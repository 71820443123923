var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "login-container",
      style: "background-image: url(" + _vm.backgroundImageSrc + ")",
    },
    [
      _c("div", {
        staticClass: "logo",
        style: "background-image: url(" + _vm.mainlogoSrc + ")",
      }),
      _c("div", { staticClass: "content-wrapper" }, [
        _c("div", { staticClass: "main-login-wrapper" }, [
          !_vm.scanCodeLogin
            ? _c(
                "div",
                { staticClass: "login-wrapper" },
                [
                  _c(
                    "div",
                    { staticClass: "login-tabs-wrapper" },
                    _vm._l(_vm.loginTabs, function (title, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "tab-item-wrapper",
                          on: {
                            click: function ($event) {
                              return _vm.tabClick(index)
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              class: [
                                "tab-title",
                                _vm.loginType - 1 === index
                                  ? "tab-title-active"
                                  : "",
                              ],
                            },
                            [_vm._v(_vm._s(title))]
                          ),
                          _c("div", {
                            class: [
                              "tab-bottom-line",
                              _vm.loginType - 1 === index ? "tab-active" : "",
                            ],
                          }),
                        ]
                      )
                    }),
                    0
                  ),
                  _c("el-form", { ref: "form", attrs: { model: _vm.form } }, [
                    _vm.loginType === 1
                      ? _c(
                          "div",
                          { staticClass: "login-input-wrapper" },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "v-input-wrapper",
                                attrs: {
                                  prop: "account",
                                  rules: [
                                    {
                                      required: true,
                                      message: "请输入用户名",
                                      trigger: "change",
                                    },
                                  ],
                                },
                              },
                              [
                                _c("v-input", {
                                  ref: "account",
                                  attrs: {
                                    placeholder: "输入用户名",
                                    width: 422,
                                  },
                                  nativeOn: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      )
                                        return null
                                      return _vm.loginAction.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.form.account,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "account", $$v)
                                    },
                                    expression: "form.account",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                staticClass: "v-input-wrapper",
                                attrs: {
                                  prop: "password",
                                  rules: [
                                    {
                                      required: true,
                                      message: "请输入密码",
                                      trigger: "change",
                                    },
                                  ],
                                },
                              },
                              [
                                _c("v-input", {
                                  ref: "password",
                                  attrs: {
                                    placeholder: "输入密码",
                                    type: "password",
                                    width: 422,
                                  },
                                  nativeOn: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      )
                                        return null
                                      return _vm.loginAction.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.form.password,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "password", $$v)
                                    },
                                    expression: "form.password",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                  _c("el-form", { ref: "form", attrs: { model: _vm.form } }, [
                    _vm.loginType === 2
                      ? _c(
                          "div",
                          { staticClass: "login-input-wrapper" },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "v-input-wrapper",
                                attrs: {
                                  prop: "phone",
                                  rules: [
                                    {
                                      required: true,
                                      message: "请输入手机号",
                                      trigger: "change",
                                    },
                                    {
                                      validator: _vm.validatePhone,
                                      trigger: "change",
                                    },
                                  ],
                                },
                              },
                              [
                                _c("input", {
                                  staticStyle: {
                                    position: "fixed",
                                    bottom: "-99999px",
                                  },
                                  attrs: {
                                    tabindex: "-1",
                                    value: "1",
                                    type: "password",
                                  },
                                }),
                                _c("v-input", {
                                  ref: "phone",
                                  attrs: {
                                    maxlength: 11,
                                    placeholder: "输入手机号",
                                    width: 422,
                                  },
                                  nativeOn: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      )
                                        return null
                                      return _vm.loginAction.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.form.phone,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "phone", $$v)
                                    },
                                    expression: "form.phone",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                class: [
                                  "input-wrapper",
                                  _vm.validateCodeError
                                    ? "input-error-wrapper"
                                    : "",
                                ],
                                attrs: {
                                  prop: "code",
                                  rules: [
                                    {
                                      required: true,
                                      message: "请输入验证码",
                                      trigger: "change",
                                    },
                                    {
                                      validator: _vm.validateCode,
                                      trigger: "change",
                                    },
                                  ],
                                },
                              },
                              [
                                _c("input", {
                                  staticStyle: {
                                    position: "fixed",
                                    bottom: "-99999px",
                                  },
                                  attrs: {
                                    tabindex: "-1",
                                    value: "1",
                                    type: "password",
                                  },
                                }),
                                _c("v-input", {
                                  ref: "verification",
                                  attrs: {
                                    maxlength: 6,
                                    placeholder: "输入验证码",
                                    width: 272,
                                  },
                                  on: { blur: _vm.codeBlur },
                                  nativeOn: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      )
                                        return null
                                      return _vm.loginAction.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.form.code,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "code", $$v)
                                    },
                                    expression: "form.code",
                                  },
                                }),
                                _c(
                                  "div",
                                  {
                                    class: [
                                      "send-code-button",
                                      _vm.sendDisabled || !_vm.canSendCode
                                        ? "send-disabled-text"
                                        : "",
                                    ],
                                    on: { click: _vm.sendCode },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.sendText) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                  _c("v-button", {
                    staticClass: "login-button",
                    attrs: { text: "登录", width: 422, disabled: _vm.disabled },
                    on: { click: _vm.loginAction },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]),
        _c("div", { staticClass: "login-divider" }),
        _c("div", { staticClass: "login-qrcode-wrapper" }, [
          _c("span", { staticClass: "qrcode-title" }, [_vm._v("APP扫码登录")]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loginQrcodeStatus === "CONFIRM_LOGIN",
                  expression: "loginQrcodeStatus === 'CONFIRM_LOGIN'",
                },
              ],
              staticClass: "login-qrcode",
              attrs: {
                "element-loading-background": "rgba(255, 255, 255, 0.95)",
              },
            },
            [
              _c("div", {
                staticClass: "login_img",
                attrs: { id: "login_qrcode_img" },
              }),
              _vm.loginQrcodeStatus === "QR_EXPIRED" ||
              _vm.loginQrcodeStatus === "SCAN_SUCCESS"
                ? _c("div", { staticClass: "login_img_cover" })
                : _vm._e(),
              _vm.loginQrcodeStatus === "QR_EXPIRED"
                ? _c("img", {
                    staticClass: "icon-refresh",
                    attrs: { src: require("./images/scan_refresh.png") },
                    on: { click: _vm.scanRefresh },
                  })
                : _vm._e(),
            ]
          ),
          _vm.loginQrcodeStatus === "CONFIRM_LOGIN"
            ? _c("span", { staticClass: "qrcode-footer-logining" }, [
                _vm._v("正在登陆…"),
              ])
            : _vm._e(),
          _vm.loginQrcodeStatus === "CREATE_SUCCESS" ||
          _vm.loginQrcodeStatus === "QR_EXPIRED"
            ? _c("span", { staticClass: "qrcode-footer" }, [
                _vm._v("请使用管理端APP扫描二维码登录"),
              ])
            : _vm._e(),
          _vm.loginQrcodeStatus === "SCAN_SUCCESS"
            ? _c("div", { staticClass: "scan-tip-wrapper" }, [
                _c("img", {
                  staticClass: "scan-tip-icon",
                  attrs: { src: require("./images/scan_login_scaned.png") },
                }),
                _vm._m(0),
              ])
            : _vm._e(),
          _vm.loginQrcodeStatus === "CANCEL_LOGIN"
            ? _c("div", { staticClass: "scan-tip-wrapper" }, [
                _c("img", {
                  staticClass: "scan-tip-icon",
                  attrs: { src: require("./images/scan_login_cancel.png") },
                }),
                _vm._m(1),
              ])
            : _vm._e(),
        ]),
      ]),
      _c("div", { staticClass: "footer" }, [
        _c("div", { staticClass: "copyright" }, [
          _c("span", { domProps: { textContent: _vm._s(_vm.copyright) } }),
          _c("a", {
            staticClass: "beian-link",
            attrs: { href: "http://www.beian.miit.gov.cn", target: "_blank" },
            domProps: { textContent: _vm._s(_vm.icpNO) },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "scan-tip-content-wrapper" }, [
      _c("span", { staticClass: "scan-tip-title" }, [_vm._v("扫描成功")]),
      _c("span", { staticClass: "scan-tip-content" }, [
        _vm._v("请在APP中点击确认即可登录"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "scan-tip-content-wrapper" }, [
      _c("span", { staticClass: "scan-tip-title" }, [
        _vm._v("您已取消此次登录"),
      ]),
      _c("span", { staticClass: "scan-tip-content" }, [
        _vm._v("您可再次扫描登录，或关闭窗口"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }