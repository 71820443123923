<template>
  <div class="login-container" :style="'background-image: url(' + backgroundImageSrc + ')'">
    <div class="logo" :style="'background-image: url(' + mainlogoSrc + ')'"></div>
    <div class="content-wrapper">
      <!-- 账号密码、手机验证码登录 -->
        <div class="main-login-wrapper">
        <div v-if="!scanCodeLogin" class="login-wrapper">
          <div class="login-tabs-wrapper">
            <div class="tab-item-wrapper" v-for="(title, index) in loginTabs" :key="index" @click="tabClick(index)">
              <div :class="['tab-title',loginType - 1 === index ? 'tab-title-active':'']">{{title}}</div>
              <div :class="['tab-bottom-line',loginType - 1 === index ? 'tab-active':'']"></div>
            </div>
          </div>
          <el-form ref="form" :model="form">
          <div v-if="loginType === 1" class="login-input-wrapper">
            <el-form-item class="v-input-wrapper" prop="account" :rules="[ { required: true, message: '请输入用户名', trigger: 'change' }]">
              <v-input ref="account" placeholder="输入用户名" v-model="form.account" :width="422" @keyup.enter.native="loginAction"></v-input>
            </el-form-item>
            <el-form-item class="v-input-wrapper" prop="password" :rules="[ { required: true, message: '请输入密码', trigger: 'change' }]">
              <v-input ref="password" placeholder="输入密码" v-model="form.password" type="password" :width="422" @keyup.enter.native="loginAction"></v-input>
            </el-form-item>
          </div>
          </el-form>
          <el-form  ref="form" :model="form">
          <div v-if="loginType === 2" class="login-input-wrapper">
            <el-form-item class="v-input-wrapper" prop="phone"
            :rules="[
                { required: true, message: '请输入手机号', trigger: 'change' },
                { validator: validatePhone, trigger: 'change' }]">
              <input tabindex=-1 value="1" type="password" style="position:fixed;bottom:-99999px;">
              <v-input ref="phone" :maxlength="11" placeholder="输入手机号" v-model="form.phone" :width="422" @keyup.enter.native="loginAction"></v-input>
            </el-form-item>
            <el-form-item :class="['input-wrapper',validateCodeError ? 'input-error-wrapper' : '']" prop="code"
            :rules="[
                { required: true, message: '请输入验证码', trigger: 'change' },
                { validator: validateCode, trigger: 'change' }]">
              <input  tabindex=-1 value="1" type="password" style="position:fixed;bottom:-99999px;">
              <v-input ref="verification" :maxlength="6" placeholder="输入验证码" v-model="form.code" :width="272" @blur="codeBlur" @keyup.enter.native="loginAction"></v-input>
              <div :class="['send-code-button',(sendDisabled || !canSendCode) ? 'send-disabled-text' : '']"
                @click="sendCode">
                {{sendText}}
              </div>
            </el-form-item>
          </div>
          </el-form>
          <v-button class="login-button" text="登录" :width="422" :disabled="disabled" @click="loginAction" ></v-button>
        </div>
        </div>
      <!-- 扫码登录 -->
      <div class="login-divider"></div>
      <div class="login-qrcode-wrapper">
        <span class="qrcode-title">APP扫码登录</span>
        <div class="login-qrcode"
             v-loading="loginQrcodeStatus === 'CONFIRM_LOGIN'"
             element-loading-background="rgba(255, 255, 255, 0.95)"
             >
          <div id="login_qrcode_img" class="login_img"></div>
          <div v-if="loginQrcodeStatus==='QR_EXPIRED' || loginQrcodeStatus==='SCAN_SUCCESS'" class="login_img_cover"></div>
          <img v-if="loginQrcodeStatus==='QR_EXPIRED'" src="./images/scan_refresh.png" class="icon-refresh" @click="scanRefresh">
        </div>

        <span v-if="loginQrcodeStatus==='CONFIRM_LOGIN'" class="qrcode-footer-logining">正在登陆…</span>
        <span v-if="loginQrcodeStatus==='CREATE_SUCCESS' || loginQrcodeStatus==='QR_EXPIRED'" class="qrcode-footer">请使用管理端APP扫描二维码登录</span>

        <div v-if="loginQrcodeStatus==='SCAN_SUCCESS'" class="scan-tip-wrapper">
          <img src="./images/scan_login_scaned.png" class="scan-tip-icon">
          <div class="scan-tip-content-wrapper">
            <span class="scan-tip-title">扫描成功</span>
            <span class="scan-tip-content">请在APP中点击确认即可登录</span>
          </div>
        </div>
        <div v-if="loginQrcodeStatus==='CANCEL_LOGIN'" class="scan-tip-wrapper">
          <img src="./images/scan_login_cancel.png" class="scan-tip-icon">
          <div class="scan-tip-content-wrapper">
            <span class="scan-tip-title">您已取消此次登录</span>
            <span class="scan-tip-content">您可再次扫描登录，或关闭窗口</span>
          </div>
        </div>
      </div>
    </div>

    <div class="footer">
      <div class="copyright">
        <span v-text="copyright"></span>
        <a class="beian-link" href="http://www.beian.miit.gov.cn" target="_blank" v-text="icpNO"></a>
      </div>
    </div>
  </div>
</template>

<script>
import crypto from 'crypto'
import { _localStorage, sessionStorageHelper } from 'common/utils'
import { mobileRegular, verifyCodeRegular } from 'common/regular'
import QRCode from 'qrcodejs2'

const defaultBackgroundImageSrc = require('./images/login_bg.png')
const defaultMainlogoSrc = require('./images/main_logo.png')

export default {
  name: 'login',
  data () {
    return {
      disabled: false,
      account: '',
      password: '',
      // 扫描登录
      scanCodeLogin: false,
      // 1账号密码 2验证码  3扫码
      loginType: 1,
      loginTabs: ['账号密码', '手机验证码'],
      sendText: '获取验证码',
      sendDisabled: false,
      form: {
        account: '',
        password: '',
        phone: '',
        code: ''
      },
      sendCodeTimer: undefined,
      qrCodeTimer: undefined,
      qrCodeTime: 10,
      time: 60,
      validateCodeError: false,
      qrCode: '',
      loginQrcodeStatus: 'QR_EXPIRED', // CREATE_SUCCESS, QR_EXPIRED, CANCEL_LOGIN ,SCAN_SUCCESS ,CONFIRM_LOGIN
      webSocketIndex: 0,
      webSocket: null
    }
  },
  mounted () {
    this.dealWithQRCodeShow()
  },
  computed: {
    backgroundImageSrc () {
      return this.$store.state.baseConfig.backgroundImageSrc || defaultBackgroundImageSrc
    },
    mainlogoSrc () {
      return this.$store.state.baseConfig.mainlogoSrc || defaultMainlogoSrc
    },
    copyright () {
      return this.$store.state.baseConfig.copyright
    },
    icpNO () {
      return this.$store.state.baseConfig.icpNO
    },

    canSendCode () {
      let regExp = mobileRegular
      return regExp.test(this.form.phone)
    }
  },
  // 离开页面时，需要清除的一些东西在此操作
  destroyed () {
    clearInterval(this.sendCodeTimer)
    this.dealSetQRCodeExpired()
  },

  created () {
    // 进入登录页面，清空token
    this.$axios.defaults.headers.token = ''
    _localStorage.removeItem('token')
  },
  methods: {
    loginAction () {
      let _this = this
      let props = this.loginType === 1 ? ['account', 'password'] : ['phone', 'code']
      let canContinue = true
      this.$refs.form.validateField(props, (errorMessage) => {
        if (errorMessage && errorMessage.length) {
          if (errorMessage === '请输入验证码' || errorMessage === '您填写验证码格式不正确') {
            _this.validateCodeError = true
          } else {
            if (_this.validateCodeError) {
              _this.validateCodeError = false
            }
          }
          canContinue = false
        }
      })
      if (!canContinue) {
        return
      }

      let account = this.loginType === 1 ? this.form.account : this.form.phone
      let password = this.loginType === 1 ? crypto.createHash('md5').update(this.form.password).digest('hex') : this.form.code
      let mode = this.loginType === 1 ? 'PASSWORD' : 'VERIFICATION'
      // 密码登录PASSWORD 验证码登录VERIFICATION
      let postData = {
        username: account,
        password: password,
        mode: mode
      }
      this.requestForLogin(postData)
    },

    requestForLogin (pramas) {
      let _this = this
      _this.disabled = true
      _this.$axios({
        method: 'post',
        url: `${API_CONFIG.butlerBaseURL}login`,
        data: this.$qs.stringify(pramas)
      }).then(async res => {
        _this.disabled = false
        if (res.status === 100) {
          let data = res.data
          let companyCode = data.companyCode
          let subPlatformId = data.subPlatformId
          let childPlatformId = data.childPlatformId
          let availablePlatformIds = data.availablePlatformIds
          if (!availablePlatformIds || !availablePlatformIds.length) {
            _this.$message({
              type: 'error',
              message: '子平台权限出错了, 联系管理员',
              center: true
            })
            return
          }

          _localStorage.setItem('companyCode', companyCode)
          let token = data.token
          if (token && token.length > 0) {
            _this.$axios.defaults.headers.token = token
            _this.$store.commit('setToken', token)
            _localStorage.setItem('token', token)
          }
          const getCookie = await this.getCookie()
          console.log(getCookie, '--不要删除--')
          _this.$router.push({
            name: 'home',
            query: {
              login: 1,
              cid: childPlatformId,
              pid: subPlatformId === 0 ? availablePlatformIds[0] : subPlatformId,
              newLogin: 2
            }
          })
        } else {
          _this.password = ''
          _this.$refs.password.focus()
          if (pramas && pramas.mode === 'QR') {
            _this.loginQrcodeStatus = 'QR_EXPIRED'
            _this.dealSetQRCodeExpired()
          }
        }
      }).catch(res => {
        if (pramas && pramas.mode === 'QR') {
          _this.loginQrcodeStatus = 'QR_EXPIRED'
          _this.dealSetQRCodeExpired()
        }
      })
    },
    async getCookie () {
      let res = await this.$axios.get(`${API_CONFIG.baseURL}userAction!getCookie.action`)
      console.log(res, '用于老接口请求时携带的cookie能生效,不要删除')
    },
    tabClick (index) {
      this.$refs.form && this.$refs.form.clearValidate()
      if (this.validateCodeError) {
        this.validateCodeError = false
      }
      this.loginType = index + 1
    },

    validatePhone (rule, value, callback) {
      let regExp = mobileRegular
      if (!regExp.test(value)) {
        callback(new Error('您填写手机号码格式不正确'))
      } else {
        callback()
      }
    },

    validateCode (rule, value, callback) {
      let regExp = verifyCodeRegular
      if (!regExp.test(value)) {
        this.validateCodeError = true
        callback(new Error('您填写验证码格式不正确'))
      } else {
        this.validateCodeError = false
        callback()
      }
    },

    async sendCode () {
      if (this.sendDisabled || !this.canSendCode) {
        return
      }
      let postData = {
        type: 7,
        phone: this.form.phone
      }
      const { status } = await this.$axios({
        method: 'post',
        url: `${API_CONFIG.butlerBaseURL}common/sendCode`,
        headers: {
          mobileType: 999
        },
        data: this.$qs.stringify(postData)
      })

      if (status === 100) {
        this.sendDisabled = true
        this.startTimer()
        this.$message.success('验证码发送成功')
      }
    },

    startTimer () {
      let _this = this
      this.sendCodeTimer = setInterval(() => {
        if (_this.time <= 0) {
          _this.time = 60
          _this.sendText = '重新获取'
          _this.sendDisabled = false
          clearInterval(_this.sendCodeTimer)
        } else {
          _this.sendText = `${_this.time}s后重发`
          _this.time--
        }
      }, 1000)
    },

    // 验证码输入框失焦处理
    codeBlur (event) {
      if (this.form.code.length === 0) {
        this.validateCodeError = true
      }
    },
    // 创建二维码
    async dealWithQRCodeShow () {
      const { data } = await this.$axios({
        method: 'get',
        url: `${API_CONFIG.butlerBaseURL}manager/qrLogin/code`
      })
      if (!data) {
        return
      }
      this.qrCode = data.qrCode
      this.qrCodeTime = data.validityPeriod
      this.createSocket()
      let dom = document.getElementById('login_qrcode_img')
      if (dom) {
        while (dom.firstChild) {
          dom.removeChild(dom.firstChild)
        }
        let encryCode = data.qrCode
        if (encryCode && encryCode.length) {
          let id = `login_qrcode_img`
          this.$nextTick(() => {
                new QRCode(id, { // eslint-disable-line
              width: 324,
              height: 324,
              text: encryCode,
              correctLevel: 3
            })
          }, 20)
          this.loginQrcodeStatus = 'CREATE_SUCCESS'
          this.startQrCodeTimer()
        }
      }
    },
    startQrCodeTimer () {
      let _this = this
      let time = _this.qrCodeTime || 0
      this.qrCodeTimer = setInterval(() => {
        if (time <= 0) {
          if (_this.loginQrcodeStatus !== 'CONFIRM_LOGIN') {
            _this.loginQrcodeStatus = 'QR_EXPIRED'
          }
          clearInterval(_this.qrCodeTimer)
          this.webSocket && this.webSocket.close()
        } else {
          time--
        }
      }, 1000)
    },

    getWebSocketUri () {
      let webSocketUri = ''
      const currentUrl = API_CONFIG.butlerBaseURL.substr(0, API_CONFIG.butlerBaseURL.indexOf('manage'))
      const wsProtocol = location.protocol === 'https:' ? 'wss://' : 'ws://'
      webSocketUri = `${wsProtocol}${currentUrl}socket`
      webSocketUri = webSocketUri.replace(/(https|http):\/\//g, '')
      return webSocketUri
    },
    createSocket () { // 初始化weosocket
      /* eslint-disable */
      const wsuri = this.getWebSocketUri ()
      this.webSocket = new WebSocket(wsuri)
      this.webSocket.onopen = this.webSocketOnOpen
      this.webSocket.onmessage = this.webSocketOnMessage
      this.webSocket.onerror = this.webSocketOnError
      this.webSocket.onclose = this.webSocketClose
    },
    webSocketOnOpen () { // 连接建立之后执行send方法发送数据
      let data = {
        code: '11000002',
        body: { qrCode: this.qrCode|| ''}
      }
      this.webSocketIndex = 0
      this.webSocketSend(JSON.stringify(data))
    },
     webSocketOnError () { // 连接建立失败重连
      this.webSocketIndex++
      if (this.webSocketIndex < 15) {
        this.createSocket()
      }
    },
    webSocketOnMessage (e) { // 数据接收
      const readData = JSON.parse(e.data)
      if(!readData || !readData.body){
        return
      }
      this.loginQrcodeStatus = readData.body && readData.body.action
      if(readData.body && readData.body.code && readData.body.action === 'CONFIRM_LOGIN'){
        let pramas = {
          username : readData.body.code,
          mode : 'QR'
        }
        this.requestForLogin (pramas)
      }
    },
    webSocketSend (Data) { // 数据发送
      this.webSocket.send(Data)
    },
    scanRefresh () {
      this.dealWithQRCodeShow()
    },
    dealSetQRCodeExpired (){
      this.qrCodeTimer && clearInterval(this.qrCodeTimer)
      this.webSocket && this.webSocket.close()
    },
    enterClick (){
      console.log('enter 点击')
    }
  }
}
</script>

<style scoped lang="scss">
.login-container {
  width: 100%;
  height: 100%;
  // background-image: url('./images/login_bg.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .logo {
      position: absolute;
      display: inline-block;
      top: 40px;
      left: 40px;
      width: 230px;
      height: 72px;
      // background-image: url('./images/main_logo.png');
      background-size: 100%;
      background-repeat: no-repeat;
      // margin-bottom: 64px;
    }

  .content-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    .login-divider{
      width: 1px;
      height: 200px;
      background: #ffffff;
      margin: 0 100px;
    }
    .login-qrcode{
      width: 360px;
      height: 360px;
      margin:26px 0 16px 0;
      background:rgba(255,255,255,1);
      border-radius:4px;
      display:flex;
      font-size: 40px;
      color:#A2A0A0;
      justify-content:center;
      align-items:center;
      .login_img_cover{
        position:absolute;
        width:360px;
        height:360px;
        background:rgba(255,255,255,1);
        border-radius:4px;
        opacity:0.95;
        display:flex;
        justify-content:center;
        align-items:center;
      }
      .icon-refresh{
        width:76px;
        height:76px;
        position:absolute;
        }
    }
    .qrcode-title{
      font-size:24px;
      font-family:PingFangSC-Semibold,PingFang SC;
      font-weight:600;
      color:rgba(255,255,255,1);
    }
    .qrcode-footer-logining{
      font-size:24px;
      font-family:PingFangSC-Semibold,PingFang SC;
      font-weight:600;
      color:rgba(255,255,255,1);
    }
    .qrcode-footer{
      font-size:20px;
      font-family:PingFangSC-Regular,PingFang SC;
      font-weight:400;
      color:rgba(255,255,255,1);
    }
  }
  .main-login-wrapper {
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    width:528px;
    height:559px;
    background: white;
    border-radius:6px;
    overflow: hidden;

    .login-wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      display: flex;
      flex-direction: column;

      .login-tabs-wrapper {
        margin-top: 50px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;

        .tab-item-wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 120px;
          margin-left: 30px;
          margin-right: 30px;

          .tab-title {
            font-size:24px;
            font-family:PingFangSC-Regular,PingFang SC;
            font-weight:500;
            line-height:33px;
            color: #333333;
            text-align: center;
          }

          .tab-title-active {
            font-family:PingFangSC-Medium,PingFang SC;
            color: #007AFF;
          }

          .tab-bottom-line {
            margin-top: 10px;
            width:50px;
            height:4px;
          }

          .tab-active {
            background:#007AFF;
          }
        }

      }

      .login-input-wrapper {
        display: flex;
        flex-direction: column;
      }

      .v-input-wrapper {
         margin: 30px auto;
         width:422px;
        //  height:74px;

         ::v-deep .el-input__inner {
          height: 70px;
          font-size:22px;
          font-family:PingFangSC-Regular,PingFang SC;
          font-weight:400;
          color:#333333;
          box-shadow: 0 0 0px 1000px white inset !important;
        }
      }

      ::v-deep .el-form-item__error {
        font-size:18px;
        font-family:PingFangSC-Regular,PingFang SC;
        font-weight:400;
      }

      .input-wrapper {
        margin: 30px auto;
        width:422px;
        height:70px;
        border-radius:3px;
        border:1px solid #C9C9C9;

        ::v-deep .el-form-item__content {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        }

        ::v-deep .el-input__inner {
          margin-top: 1px;
          margin-left: 1px;
          height: 68px;
          font-size:22px;
          font-family:PingFangSC-Regular,PingFang SC;
          font-weight:400;
          color:#333333;
          border: none;
          outline: none;
          box-shadow: 0 0 0px 1000px white inset !important;
        }

        .send-code-button {
          flex-shrink: 0;
          width: 130px;
          margin-right: 20px;
          color: #007AFF;
          font-size:22px;
          font-family:PingFangSC-Regular,PingFang SC;
          font-weight:400;
          text-align: center;
          cursor: pointer;
          &:hover {
            background: rgba(201,201,201,0.2);
          }
        }

        .send-disabled-text {
          color: #999999;
          cursor: not-allowed;
          &:hover {
            background: #fff;
          }
        }
      }

      .input-error-wrapper {
         border:1px solid #F56C6C !important;
      }

      .login-button {
        height: 74px;
        margin-top: 30px;
        ::v-deep .el-button {
          font-size:24px;
          font-family:PingFangSC-Semibold,PingFang SC;
          font-weight:600;
          color:#FFFFFF;
        }
      }
    }
  }
  .footer {
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translate(-50%);
    font-size: 12px;
    color: #fff;
    .beian-link {
      margin-left: 10px;
      text-decoration: none;
      &:link, &:visited, &:hover, &:active {
        text-decoration: none;
        color: #fff;
      }
    }
  }
  .scan-tip-wrapper{
    display:flex;
    .scan-tip-icon{
      width:56px;
      height:56px;
    }
    .scan-tip-content-wrapper{
      display:flex;
      flex-direction:column;
      margin-left:14px;
      .scan-tip-title{
        font-size:22px;
        font-family:PingFangSC-Regular,PingFang SC;
        font-weight:400;
        text-align:left;
        color:rgba(255,255,255,1);
      }
      .scan-tip-content{
        font-size:18px;
        text-align:left;
        font-family:PingFangSC-Regular,PingFang SC;
        font-weight:400;
        color:rgba(255,255,255,1);
      }
    }
  }
  ::v-deep .el-loading-spinner .path{
    width: 76px;
    height: 76px;
    stroke: #A2A0A0;
    stroke-width: 3;
  }
}
</style>
